#top_page {
    position: relative;
}

.Image {
    width: 100%
}

.Title {
    font-size: 20px;
    margin-block-start: 10px;
    margin-block-end: 10px;
}

.SubTitle {
    font-size: 18px;
    margin-block-start: 10px;
    margin-block-end: 10px;
}

.Sentence {
    font-size: 14px;
    margin-top: 20px;
    margin-left: 3.5%;
}