@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#photo_share_page {
    background-color: #F2E5D5;
}

#photo_share_titles {
    position: relative;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
    padding: 8% 3.5% 0 3.5%;
}

#photo_share_top_img {
    margin: 0;
    display: grid;
}

#photo_share_content {
    display: grid;
    justify-items: center;

    position: relative;
}

#photo_share_content p {
    font-size: 12px;
    margin: 0.5em;
}

#photo_share_content_sentence {
    width: 85%;
    padding: 1em;

    position: absolute;
    margin: 1em 5%;
    background-color: #E0D4C2;
}

#photo_share_line {
    position: absolute;
    margin-top: 22rem;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(43, 156, 43);
    color: white;
    width: 40%;
    height: 2.5rem;
    border-radius: 5%;
}

#photo_share_content_bg {
    width: 100%;
    object-fit: cover;
}
