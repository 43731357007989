@import url('https://use.fontawesome.com/releases/v5.6.1/css/all.css');

#menu_button_wrapper {
    position: absolute;
    right: 0;

    display: flex;
    text-align: right;
    align-items: center;
    margin-top: 8px;

    z-index: 1;
}
#menu_button {
    margin-right: 20px;

    width: 30px; /* サークルの直径に基づいて適切な幅を調整してください */
    height: 30px; /* サークルの直径に基づいて適切な高さを調整してください */
    border-radius: 50%; /* 円形の形状を作成するための丸い角 */
    background-color: transparent; /* サークルの背景色 */
    border: 1px solid black; /* サークルの枠線 */
    cursor: pointer;
}

#menu_button.open {
    border: 0.1px solid white; /* サークルの枠線 */
}

.burger {
    height: 20px;
    width: 20px;
    position: relative;
    font-size: 4px;
    cursor: pointer;
    -webkit-transition: .1s all;
    -o-transition: .1s all;
    transition: .1s all;
    -webkit-tap-highlight-color: transparent;
}

.burger .burger-lines:after {
    left: 0;
    top: -1px;
}

.burger .burger-lines:before {
    left: 1px;
    top: 1px;
}

.burger:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
}

.burger .burger-lines {
    top: 50%;
    margin-top: -0.5px;
    left: 25%;
}

.burger .burger-lines {
    pointer-events: none;
    display: block;
    content: '';
    width: 50%;
    /* border-radius: 0.25em; */
    background-color: black;
    height: 0.1px;
    position: absolute;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.burger .burger-lines:after,
.burger .burger-lines:before {
    pointer-events: none;
    display: block;
    content: '';
    width: 100%;
    /* border-radius: 0.25em; */
    background-color: black;
    height: 0.10em;
    position: absolute;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}

.burger .burger-lines:after {
    left: 0;
    top: -5px;
}

.burger .burger-lines:before {
    left: 0;
    top: 5px;
}

.burger.burger-squeeze .burger-lines,
.burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
    -webkit-transition: .1s top .1s, .05s left, .1s transform, .1s background-color .1s; /* 半分の時間に設定 */
    -o-transition: .1s top .1s, .05s left, .1s transform, .1s background-color .1s; /* 半分の時間に設定 */
    transition: .1s top .1s, .05s left, .1s transform, .1s background-color .1s; /* 半分の時間に設定 */
}

/* .burger.burger-squeeze .burger-lines:after,
.burger.burger-squeeze .burger-lines:before {
    width: 2em;
} */

.burger.burger-squeeze.open .burger-lines,
.burger.burger-squeeze.open .burger-lines:after,
.burger.burger-squeeze.open .burger-lines:before {
    -webkit-transition: .1s background-color, .1s top, .1s left, .1s transform .075s; /* 半分の時間に設定 */
    -o-transition: .1s background-color, .1s top, .1s left, .1s transform .075s; /* 半分の時間に設定 */
    transition: .1s background-color, .1s top, .1s left, .1s transform .075s; /* 半分の時間に設定 */
}

.burger.burger-squeeze.open .burger-lines {
    background-color: transparent;
}

.burger.burger-squeeze.open .burger-lines:before,
.burger.burger-squeeze.open .burger-lines:after {
    left: 0em;
    top: 0px;
    background-color: white;
}

.burger.burger-squeeze.open .burger-lines:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.burger.burger-squeeze.open .burger-lines:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}