@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* #seat_page {
} */

#seat_body {
    position: relative;

    background-color: #fff;
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;

    padding: 50px 0 0 0;
}

#seat_title {
    margin: 0 3.5%;
}

#seat_location {
    font-size: 16px;
    display: grid;
    justify-items: center;
    margin: 0.2em 0;
}

#seat_detail {
    width: 100%;
    height: 350px;

    background-color: #e9899b;
    position: relative;
    text-align: center;
}

#seat_detail_default {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
}

#seat_detail_selected {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);

    display: grid;
    gap: 0 2em;
    grid-template-columns: 3fr 1fr 3fr;

    width: 95%;

    font-size: 16px;
}

.seat_table_colm.left {
    margin-left: auto;
}
.seat_table_colm.right {
    margin-right: auto;
}
.seat_table_colm p {
    margin: 0;
}

.seat_table_item {
    padding: 0.6em 0;
    text-align: left;
    align-items: center;
}
.seat_table_name {
    display: flex;
    font-size: 16px;
    border-bottom: 0.1rem dotted white;
}
.seat_table_name .name {
    margin: 0 1em 0 0;
}
.seat_table_name .name_title {
    margin-left: auto;
}


.seat_table_explan {
    display: grid;
    justify-items: center;

    font-size: 16px;
}

.seat_table_side {
    writing-mode: vertical-rl;
    margin-top: auto;
}

.seat_table_relationship {
    writing-mode: vertical-rl;
    margin-bottom: auto;
}

.seat_table_circle {
    width: 2.5em;
    height: 2.5em;

    margin: auto auto;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    color: white;
    border-radius: 50%;
    border: 0.1em solid;
}

#seat_detail_default p {
    margin: 0.5em 0;
    font-size: 16px;
}

#seat_graph_wrapper {
    margin: 3em auto;
    width: 60%;
    display: grid;
    justify-items: center;
}

#seat_graph_main {
    width: 50%;
    height: 2.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 16px;
    background-color: #e9899b;
    border-radius: 5%;

    margin-bottom: 0.5em;
}

.seat_graph_colm{
    display: grid;
    gap: 0 1em;
}

#seat_graph_row1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
#seat_graph_row2 {
    grid-template-columns: 3fr 1fr 3fr;
}
#seat_graph_row3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.seat_graph_colm .left {
    display: grid;
    justify-items: right;
}

#seat_attention {
    padding-bottom: 2em;
    text-align: center;
}
#seat_attention p {
    margin: 0.5em 0;
    font-size: 16px;
}