@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.DrinkTitle {
    padding: 4px 0;
    border-top: 3px double black;
    border-bottom: 3px double black;
    text-align: center;
    color: black;
    width: 40%;
    margin-top: 1.5em;
}

/* #dish_page {
} */

#dish_body {
    position: relative;

    background-color: #fff;
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;

    /* padding: 8% 3.5%; */
}

#dish_content {
    display: grid;
    justify-items: center;
}

.dish_wrapper {
}

.dish_category {
    font-size: 16px;
    display: grid;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
}

.dish_subcategory {
    display: grid;
    font-size: 14px;
    margin: 0;
    align-items: center;
    justify-content: center;
}

.dish_item {
    font-size: 14px;
    margin-left: 5%;
}

.dish_item p {
    margin-top: 0;
    margin-bottom: 0;
}

.drink_exp {
    font-size: 10px;
    margin-left: 5%;
}