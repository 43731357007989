#menu {
    width: 100%;
    position: fixed;
    z-index: 2;
}

#menu_wrapper {
    width: 100%;
    height: 100%;
}

#menu_background {
    position: fixed;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0;
    /* グラデーションを適用するプロパティと時間を指定 */
    transition: opacity 0.2s ease;
}

#menu_background.visible {
    opacity: 0.3;
}
