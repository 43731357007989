@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#photo {
    margin-top: 60px;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;

    display: grid;
    position: relative;
}

#photo_image1 {
    width: 70%;
    margin-left: auto;
    margin-right: 1em;
}
#photo_image2 {
    position: absolute;
    width: 30%;
    left: 0;
    top: 10em;
}
#photo_image3 {
    width: 60%;
    margin: 7.5em 0 0 auto;
}
#photo_image4 {
    width: 80%;
    margin-top: 2.5em;
}