@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#profile {
    margin-top: 50px;
    margin-left: 3.5%;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
}

#profile_image_wrapper {
    margin-top: 20px;
    position: relative;
}

#profile_image {
    width: 96%;
    border-radius: 3%;
}

#profile_image2 {
    position: absolute;
    width: 40%;
    right: 0;
    bottom: 30px;
}

#profile_sentence p {
    margin-block-start: 8px;
    margin-block-end: 8px;
}

#read_more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

#read_more_text {
    border: solid 1px;
    padding: 2px 50px;
}