@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#profile_page {
    background-color: #fff;
}

#profile_top_img {
    margin: 0;
    display: grid;
}

.profile_content {
    margin: 2em 9%;
}

.profile_table {
    width: 100%;
}
.profile_table td {
    height: 35px;
}
.profile_table td.td_first {
    width: 50%;
}

#profile_favorite_groom {
    margin: 2em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1em;  

    /* justify-items: center; */
    /* align-items: center; */
}

#profile_favorite_groom .Image {
    display: grid;
    width: 90%;
    border-radius: 5%;
}

.favorite_content {
}

.favorite_title {
    font-size: 16px;
    margin: 0;
}

.favorite_subtitle {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;

    /* margin: 0 0 1em 0; */
}

.favorite_explan {
    /* margin: 1em 0 0 0; */
}

.favorite_explan p {
    margin: 0;
    font-size: 12px;
}

#profile_content {
    display: grid;
    justify-items: center;

    position: relative;
}

#profile_content p {
    font-size: 12px;
    margin: 0.5em;
}

#profile_content_sentence {
    width: 85%;
    padding: 1em;

    margin: 1em 5%;
}

#profile_line {
    position: absolute;
    margin-top: 22rem;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(43, 156, 43);
    color: white;
    width: 40%;
    height: 2.5rem;
    border-radius: 5%;
}

#profile_content_bg {
    width: 100%;
    object-fit: cover;
}


#qa {
    display: grid;
    /* grid-template-columns: 3fr 1fr; */
    /* gap: 0 0.5em; */
    /* margin: 0 0.5em; */
}

.qa_item {
    display: grid;
    grid-template-columns: 1fr 7fr;
    gap: 0 0.5em;
    margin: 1em 0 1em 0;
}

.qa_nums p {
    margin: 0;
}

.qa_no {
    font-size: 12px;
}

.qa_num {
    font-size: 26px;
}

.qa_content {
    display: grid;
    grid-template-columns: 1fr 15fr;
    gap: 0 0.1em;
}

.qa_body {
    font-size: 12px;
}
.qa_body p {
    margin: 0;
}

#history {
    display: grid;
    grid-template-columns: 1fr 5.5em 5.5em 1fr;
    gap: 0 0.1em;
}

#history_groom_explan {
}

#history_bride_explan {
}

.history_eplain {
    margin: 1em 1em 2em 1em;
}
.history_eplain p {
    margin: 0;
}
.history_title {
    border-bottom: 2px solid black;
    text-align: center;
    font-size: 16px;
}
.history_content {
    margin-top: 0.5em;

    font-size: 12px;
}

#history_groom_photo {
    border-left:2px solid #000000; /*線の設定*/
    padding: 0.3em; /*余白の設定*/
}

#history_bride_photo {
    border-right:2px solid #000000; /*線の設定*/
    padding: 0.3em; /*余白の設定*/
}

.history_photo {
    border-radius: 50%;
    margin: 0.5em 0;
}