@import url('https://use.fontawesome.com/releases/v5.6.1/css/all.css');

#menu_body {
    /* position: relative; */

    position: fixed;
    right: 0;
    font-size: 16px;
    width: 60%;

    background-color: rgb(127, 127, 127);
    padding: 30px;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
    color: white;

    opacity: 1;
    /* グラデーションを適用するプロパティと時間を指定 */
    transition: opacity 0.2s ease;
}

#menu_body.visible {
    opacity: 1;
}

#menu_index .menu_link {
    /* リンクカラー固定 */
    text-decoration: none;
    color: inherit;
}

#menu_index p {
    margin-block-start: 0;
    margin-block-end: 1.5em;
}

#information_index {
    margin-left: 10%;
}

#menu_contact {
    width: 40%;
    height: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    color: black;
    background-color: white;
}

#menu_logo_title {
    margin-top: 2em;
}