/* #top {
    display: flex;
    margin-left: 2.5%;
} */

.img {
    /* max-width: 400px; */
    /* max-height: 400px; */
    object-fit: cover;

    border-radius: 6%;
}

.scroll {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 20%;
}

.scroll_text {
    transform: rotate(90deg);
    width: auto;
}

.scroll_line {
    height: fit-content;
}

/* スクロール線 */
#wrapper {
    display: table;
    width: 100%;
    height: 100%;
}

#wrapper-inner {
    display: table-cell;
    /* vertical-align: middle; */
    width: 100%;
    height: 100%;
}

#scroll-down {
    display: block;
    position: relative;
    /* padding-top: 80px; */
    padding-bottom: 6em;
    text-align: center;
}

#scroll-down::before {
    position: absolute;
    top: 1em;
    left: 50%;
    margin-left: -2px;
    width: 0.6px;
    height: 5em;
    background: orange;
    content: ' ';
}

#scroll-down::after {
    animation: elasticus 2.2s cubic-bezier(1, 0, 0, 1) infinite;
    position: absolute;
    top: 1em;
    left: 50%;
    margin-left: -2px;
    width: 0.6px;
    height: 5em;
    background: black;
    content: ' ';
}

@keyframes elasticus {
    0% {
        transform-origin: 0% 0%;
        transform: scale(1, 0);
    }

    50% {
        transform-origin: 0% 0%;
        transform: scale(1, 1);
    }

    50.1% {
        transform-origin: 0% 100%;
        transform: scale(1, 1);
    }

    100% {
        transform-origin: 0% 100%;
        transform: scale(1, 0);
    }
}