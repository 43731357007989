.background {
    /* width: 100%; */
    position: absolute;
    background-color: #F2E5D5;
    z-index: 0;
    top: 0;
}

#background_image_container {
    display: grid;
    position: relative;
}

#background_image1 {
    width: 100%;
    padding-top: 250px;
}

#background_image2 {
    width: 100%;
}

#background_image3 {
    width: 100%;
    height: 50px;
    object-fit: cover;
}

#background_foot {
    margin-top: 1000px;
}