@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.info_button_wrapper {
    /* position: relative; */
}

.info_button_image {
    width: 100%;
    border-radius: 3%;
}

.info_button_overlay {
    /* width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center; */    
}

.info_button_title {
    /* margin-top: 40%; */
    margin-bottom: 0;
    font-size: 18px;
}

.info_button_sub_title {
    margin-bottom: 0;
    font-size: 12px;
}

.info_button_more {
    width: 3em;
    /* サークルの直径に基づいて適切な幅を調整してください */
    height: 3em;
    /* サークルの直径に基づいて適切な高さを調整してください */
    border-radius: 50%;
    /* 円形の形状を作成するための丸い角 */
    background-color: transparent;
    /* サークルの背景色 */
    border: 0.1em solid white;
    /* サークルの枠線 */
    cursor: pointer;

    line-height: 2.8em;

    display: inline-block;
    text-align: center;

    font-size: 8px;
    margin-top: 10%;
}