html {
    background-color: #fff; /* 周囲の背景色 */
}

body {
    /* margin: 0 500px; */
    /* padding: 0; */

    /* width: 100%; */
    /* height: 2000px; */
    /* display: grid; */
    /* justify-content: center; */

    /* background-color: #fff; */
}

header {
    /* margin: 0 200px; */
    position: fixed;
    z-index: 2;

    width: 100%;
    height: 50px;
    border: 1px solid #000;
    background-color: #fff;

    display: flex;
}

.slick-slide{
    /* margin-right: 3vw!important;
    margin-left: 3vw!important; */
}

.title {
    margin: 15px 0;
    font-size: 32px;
}
.subTitle {
    font-size: 20px;
}

p {
    margin: 0;
    line-height: 32px;
}

.main {
    padding-top: 50px;
}

#breadcrumbs {
    /* position: relative; */

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 2% 3.5%;
}