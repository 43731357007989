@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#timeline {
    margin-top: 60px;
    margin-left: 3.5%;
    margin-right: 3.5%;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
}