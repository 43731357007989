@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#fotter {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;

    display: grid;
    justify-items: center;

    background-color: rgb(127, 127, 127);
    color: white;

    padding-top: 50px;
    padding-bottom: 30px;
}

#logo_title {
    margin-bottom: 20px;
}

.footer_menu {
    margin: 0.7em 0;
}

#contact {
    margin-top: 20px;
    width: 14em;
    height: 2.5em;
}