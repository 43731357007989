#seat_chart_button p {
    margin: 0.5em 0;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #7d7d7d;
    color: white;
}