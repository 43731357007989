@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#information {
    margin-top: 100px;
    margin-left: 3.5%;

    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;
}

#information_buttons {
    font-family: "PT Serif", serif;
    font-weight: 400;
    font-style: normal;

    margin-right: 3.5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 5px;
}